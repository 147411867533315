<script setup>
import { usePlaningStore } from '@/stores/planning';
import { capitalFirstLetter } from '@/utilities';
import { Link } from '@inertiajs/vue3';
import { computed, inject } from 'vue';

// Components
import ButtonLink from '@/Components/Button/Link.vue';
import Icon from '@/Components/Icon.vue';

// Icons
import Left from '@i/expand_more-left.svg';
import Right from '@i/expand_more-right.svg';

// Layouts
import LocationLayout from '@/Layouts/LocationLayout.vue';

// Partials
import Calendar from './Partials/Calendar.vue';
import NoticeBar from './Partials/NoticeBar.vue';
import Shift from './Partials/Shift.vue';

const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    location: Object,
    shiftCountsByDate: Object,
    shifts: Object,
    liked_flexer_ids: Object,
    query: Object,
});
const store = usePlaningStore();
const selectedDate = computed(() => dayjs(props.query.date));
store.load({
    date: props.query.date,
    shiftsByDate: props.shiftsByDate,
    shiftCountsByDate: props.shiftCountsByDate,
});

const hasShiftsIn12Hours =
    props.shifts.filter((shift) => dayjs(shift.start).diff(dayjs(), 'hour') < 12 && dayjs().isBefore(shift.start))
        .length > 0;
</script>

<template>
    <LocationLayout title="Planning">
        <div class="relative z-0 flex flex-col gap-8 xl:flex-row">
            <div class="w-full xl:w-2/6">
                <h1 class="mb-8 text-[32px] font-bold">{{ $t('Shifts') }}</h1>
                <div class="mb-4 flex-between">
                    <Link
                        :href="
                            route('location.planning.index', {
                                date: selectedDate.subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                            })
                        "
                        class="gap-2 flex-center"
                    >
                        <Icon :icon="Left" class="relative w-2 h-auto left-3" />
                    </Link>

                    <div class="text-base font-bold text-blue">
                        {{ capitalFirstLetter(dayjs().localeData().months()[selectedDate.format('M') - 1]) }}
                    </div>

                    <Link
                        :href="
                            route('location.planning.index', {
                                date: selectedDate.add(1, 'month').startOf('month').format('YYYY-MM-DD'),
                            })
                        "
                        class="gap-2 flex-center"
                    >
                        <Icon :icon="Right" class="relative w-2 h-auto right-3" />
                    </Link>
                </div>
                <Calendar :shiftCountsByDate="shiftCountsByDate" />
                <ButtonLink :href="route('location.shifts.create', { start_date: selectedDate.format('YYYY-MM-DD') })">
                    <div class="w-full gap-4 flex-center">
                        <div class="text-base leading-none">
                            {{ $t('Add shift') }}
                        </div>
                    </div>
                </ButtonLink>
            </div>
            <div class="w-full xl:w-4/6 xl:mt-18">
                <h2 class="mb-8 text-2xl font-bold text-blue">
                    {{ selectedDate.format('D') }}
                    {{ capitalFirstLetter(dayjs().localeData().months()[selectedDate.format('M') - 1]) }}
                    {{ selectedDate.format('YYYY') }}
                </h2>
                <div class="grid">
                    <NoticeBar
                        v-if="hasShiftsIn12Hours"
                        :title="
                            $t('this shift(s) takes place within 12 hours, extra confirmation of employee is required')
                        "
                    />

                    <template v-for="shift in shifts" :key="shift.id">
                        <Shift :shift="shift" :liked_flexer_ids="liked_flexer_ids" />
                    </template>
                </div>
            </div>
        </div>
    </LocationLayout>
</template>
